<template>
  <a-menu
    mode="inline"
    theme="light"
    :open-keys="openedMenuKeys"
    v-model="currentMenus"
    @openChange="onOpenChange"
    class="menus-box"
  >
    <a-menu-item key="home" v-if="isShowMenu('menu:tenant_home')">
      <router-link :to="{ name: 'home' }">
        <a-icon type="home" />
        <span>首页</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="tenant_orders" v-if="isShowMenu('menu:tenant_orders')">
      <router-link :to="{ name: 'tenant_orders' } ">
        <a-icon type="profile" />
        <span>订单管理</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="tenant_service_order_deaths" v-if="isShowMenu('menu:tenant_dead')">
      <router-link :to="{ name: 'tenant_service_order_deaths' } ">
        <a-icon type="user-delete" />
        <span>逝者列表</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="report" v-if="isShowMenu('menu:tenant_statistics')">
      <span slot="title">
        <a-icon type="stock" />
        <span>报表统计</span>
      </span>

      <a-menu-item key="dead_data">
        <router-link :to="{ name: 'dead_data' }">
          <span>逝者数据</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="package_product_data">
        <router-link :to="{ name: 'package_product_data' }">
          <span>套餐产品数据</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="fee_data" title="费用数据">
        <a-menu-item key="fee_statistics">
          <router-link :to="{ name: 'fee_statistics' }" />
          <span>订单费用统计</span>
        </a-menu-item>

        <a-menu-item key="order_fee_details">
          <router-link :to="{ name: 'order_fee_details' }" />
          <span>订单费用明细</span>
        </a-menu-item>
        <a-menu-item key="sale_fee_details">
          <router-link :to="{ name: 'sale_fee_details' }" />
          <span>产品销售统计</span>
        </a-menu-item>
        <a-menu-item key="hall_fee_details">
          <router-link :to="{ name: 'hall_fee_details' }" />
          <span>厅房费用明细</span>
        </a-menu-item>

        <a-menu-item key="wechat_flows">
          <router-link :to="{ name: 'wechat_flows' }" />
          <span>微信小程序流水</span>
        </a-menu-item>
      </a-sub-menu>
    </a-sub-menu>

    <a-sub-menu key="business_review" v-if="isShowMenu('menu:tenant_business_review')">
      <span slot="title">
        <a-icon type="file-done" />
        <span>业务审核</span>
      </span>

      <a-menu-item key="info_modify_applies">
        <router-link :to="{ name: 'info_modify_applies' }" />
        <span>信息维护申请</span>
      </a-menu-item>

      <a-menu-item key="order_evaluates">
        <router-link :to="{ name: 'order_evaluates' }" />
        <span>评价管理</span>
      </a-menu-item>

      <a-menu-item key="messages">
        <router-link :to="{ name: 'messages' }" />
        <span>留言管理</span>
      </a-menu-item>

      <a-menu-item key="invoices">
        <router-link :to="{ name: 'invoices' }" />
        <span>发票申请</span>
      </a-menu-item>

    </a-sub-menu>

    <a-sub-menu key="tenant_setting" v-if="isShowMenu('menu:tenant_settings')">
      <span slot="title">
        <a-icon type="setting" />
        <span>系统设置</span>
      </span>

      <a-menu-item key="tenant_roles" v-if="isShowMenu('page:tenant_roles')">
        <router-link :to="{ name: 'tenant_roles' }">
          <span>角色权限</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="tenant_account_management" v-if="isShowMenu('page:tenant_users')">
        <router-link :to="{ name: 'tenant_account_management' }">
          <span>账号管理</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="information_maintenance" title="信息维护">
        <a-menu-item key="tenant_package_management">
          <router-link :to="{ name: 'tenant_package_management' }" />
          <span>套餐管理</span>
        </a-menu-item>

        <a-menu-item key="tenant_products">
          <router-link :to="{ name: 'tenant_products' }" />
          <span>产品管理</span>
        </a-menu-item>

        <a-menu-item key="cars">
          <router-link :to="{ name: 'cars' }" />
          <span>车辆管理</span>
        </a-menu-item>

        <a-menu-item key="equipments">
          <router-link :to="{ name: 'equipments' }" />
          <span>设备管理</span>
        </a-menu-item>

        <a-menu-item key="funeral_service_points">
          <router-link :to="{ name: 'funeral_service_points' }" />
          <span>殡仪服务点管理</span>
        </a-menu-item>

        <a-menu-item key="funeral_homes">
          <router-link :to="{ name: 'funeral_homes' }" />
          <span>殡仪馆管理</span>
        </a-menu-item>

        <a-menu-item key="cemeteries">
          <router-link :to="{ name: 'cemeteries' }" />
          <span>墓地管理</span>
        </a-menu-item>

        <a-menu-item key="frequently_asked_questions">
          <router-link :to="{ name: 'frequently_asked_questions' }" />
          <span>常见问题</span>
        </a-menu-item>

        <a-menu-item key="process_notification">
          <router-link :to="{ name: 'process_notification' }" />
          <span>相关手续告知书</span>
        </a-menu-item>

        <a-menu-item key="precaution">
          <router-link :to="{ name: 'precaution' }" />
          <span>注意事项</span>
        </a-menu-item>

        <a-menu-item key="conventional_way">
          <router-link :to="{ name: 'conventional_way' }" />
          <span>民俗详情</span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="tenant_sms_records">
        <router-link :to="{ name: 'tenant_sms_records' }">
          <span>短信管理</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="notification" title="通知提醒设置">
        <a-menu-item key="festival_reminds">
          <router-link :to="{ name: 'festival_reminds' }" />
          <span>节日提醒</span>
        </a-menu-item>

        <a-menu-item key="death_day_reminds">
          <router-link :to="{ name: 'death_day_reminds' }" />
          <span>忌辰提醒</span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="voucher">
        <router-link :to="{ name: 'voucher' }" />
        <span>优惠活动设置</span>
      </a-menu-item>

      <a-menu-item key="banner">
        <router-link :to="{ name: 'banner' }" />
        <span>小程序首页Banner</span>
      </a-menu-item>

      <a-menu-item key="obituary_background">
        <router-link :to="{ name: 'obituary_background' }" />
        <span>讣告背景图片</span>
      </a-menu-item>

      <a-menu-item key="vertical_screen_column">
        <router-link :to="{ name: 'vertical_screen_columns' }" />
        <span>立式屏栏目管理</span>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="hall_inspections" v-if="isShowMenu('menu:hall_inspections')">
      <router-link :to="{ name: 'hall_inspections' } ">
        <a-icon type="schedule" />
        <span>巡检记录</span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'TenantLayoutSider',
  data() {
    return {
      rootSubmenuKeys: [
        'home',
        'report',
        'tenant_setting'
      ],
      openedMenuKeys: this.$store.getters.currentMenus
    }
  },
  computed: {
    // 当前选中的菜单
    currentMenus: {
      get() {
        return this.$store.getters.currentMenus
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.openedMenuKeys = this.currentMenus
      }
    }
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openedMenuKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openedMenuKeys = openKeys
      } else {
        this.openedMenuKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>

<style lang="less" scoped>
.menus-box {
  border: none;
}
</style>
