<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="8" :xxl="8">
            <a-form-item label="联系电话">
              <a-input
                  v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
            }]"
                  autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8" :xxl="8">
            <a-form-item label="用户姓名">
              <a-input
                  v-decorator="['name', {
              normalize: this.$lodash.trim,
            }]"
                  autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8" :xxl="6">
            <a-form-item>
              <a-space>
                <a-button type="primary" html-type="submit">
                  搜索
                </a-button>
                <a-button @click="handleReset">
                  刷新
                </a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <a-space>
          <a-button type="danger" @click="allRemove">全部删除</a-button>
          <a-button type="primary" @click="addUsers">新增对象</a-button>
        </a-space>
      </div>
    </div>

    <a-table
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="users"
        :pagination="false"
        row-key="id"
    >

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="removeUser(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <add-provide-user v-if="isAddUser" :visible.sync="isAddUser" :ids="userIds" @completed="completeAddUsers"></add-provide-user>
  </div>
</template>

<script>
import AddProvideUser from "@/views/voucher/AddProvideUser.vue";

export default {
  name: "Provide",
  components: {
    AddProvideUser,
  },
  data() {
    return {
      query: {},
      data: [],
      form: this.$form.createForm(this, { name: 'provide_search' }),
      isAddUser: false,
      userIds: [],
      provideUsers: [],
      users: []
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '用户名',
          dataIndex: 'user_name',
          width: 200
        },
        {
          title: '昵称',
          dataIndex: 'nick_name',
          width: 200
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: 120
        },
      ]
    }
  },
  methods: {
    allRemove() {
      this.provideUsers = []
      this.generateUsers()
      this.$emit('completed', this.provideUsers)
    },
    handleSearch(e) {
      e.preventDefault()
      this.query = this.form.getFieldsValue()
      this.generateUsers()
    },

    generateUsers() {
      this.users = []
      for (let i = 0; i < this.provideUsers.length; i++) {
        if (this.query.phone_number) {
          if (this.provideUsers[i].phone_number.indexOf(this.query.phone_number) === -1) {
            continue
          }
        }
        if (this.query.name) {
          if (this.provideUsers[i].name.indexOf(this.query.name) === -1) {
            continue
          }
        }
        this.users.push(this.provideUsers[i])
      }
    },

    handleReset() {
      this.form.resetFields()
      this.query = this.form.getFieldsValue()
      this.generateUsers()
    },

    addUsers(){
      this.userIds = []
      if (this.provideUsers && this.provideUsers.length > 0) {
        for (let i = 0; i < this.provideUsers.length; i++) {
          this.userIds.push(this.provideUsers[i].id)
        }
      }
      this.isAddUser = true
    },
    completeAddUsers(users) {
      this.provideUsers.push(...users)
      this.generateUsers()
      this.$emit('completed', this.provideUsers)
      console.log(users)
    },
    removeUser(userId){
      let tmpUsers = []
      for (let i = 0; i < this.provideUsers.length; i++) {
        if (this.provideUsers[i].id !== userId) {
          tmpUsers.push(this.provideUsers[i])
        }
      }
      this.provideUsers = tmpUsers
      this.generateUsers()
      this.$emit('completed', this.provideUsers)
      console.log('1111')
    }
  }
}
</script>

<style scoped>

</style>
