<template>
  <div>
    <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmit"
    >
      <a-form-item label="活动名称">
        <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入活动名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
        />
      </a-form-item>

      <a-form-item label="活动类型">
        <a-select
            v-decorator="['activity_type', {
              rules: [{ required: true, message: '请选择活动类型' }]
            }]"
            @change="changeActivityType"
        >
          <a-select-option key="registered_benefits" value="registered_benefits">
            注册福利
          </a-select-option>
          <a-select-option key="information_improvement" value="complete_family_information_benefits">
            完善家属信息福利
          </a-select-option>
          <a-select-option key="discount_benefits" value="discount_benefits">
            活动福利
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="活动截止日期">
        <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm:ss"
            show-time
            v-decorator="['end_time', {
              rules: [
                { required: true, message: `请选择活动截止日期` }
              ]
            }]"
        />
      </a-form-item>

      <a-form-item label="代金券金额(元)">
        <a-input-number
            style="width: 100%;"
            :min="0.01"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['amount', {
              rules: [
                { required: true, message: '请输入代金券金额' },
              ]
            }]"
        />
      </a-form-item>

      <a-form-item label="使用条件">
        <a-select
            mode="multiple"
            v-decorator="['use_type', {
              rules: [{ required: true, message: '请选择使用条件' }]
            }]"
            :loading="voucherUseTypeOption.length === 0"
        >
          <a-select-option
              v-for="type in voucherUseTypeOption"
              :key="type.value"
              :value="type.value"
          >
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <!--        代金劵有效期-->
      <a-form-item v-if="activityType !== 'discount_benefits'" label="代金劵有效期">
        <div>
          <a-radio-group
              @change="handEffectiveType"
              v-decorator="['period_type', {
                rules: [{ required: true, message: '请选择代金劵有效期' }]
              }]"
          >
            <a-radio value="indefinite">无限期</a-radio>
            <a-radio value="fixed_length">固定时长</a-radio>
            <a-radio value="fixed_end">固定期限</a-radio>
          </a-radio-group>
        </div>
        <div v-if="effectiveType && effectiveType === 'fixed_length'">
          <a-input-number
              style="width: 60%;"
              :min="0.1"
              :max="10000000"
              :step="0.1"
              :precision="1"
              v-decorator="['period_amount', {
                rules: [
                  { required: true, message: '请输入代金劵有效期' },
                  {validator: checkEndTime}
                ]
              }]"
          />
          <a-select
              style="width: 30%;margin: 0 10px;"
              v-decorator="['period_unit', {
                rules: [{ required: true, message: '请选择代金劵有效期' },
                        {validator: checkUnit}]
              }]"
          >
            <a-select-option key="hour" value="hour">
              小时
            </a-select-option>
            <a-select-option key="day" value="day">
              天
            </a-select-option>
          </a-select>
        </div>
        <div v-if="effectiveType && effectiveType === 'fixed_end'">
          <a-date-picker
              style="width: 100%;"
              format="YYYY-MM-DD HH:mm:ss"
              show-time
              v-decorator="['period_end_time', {
                rules: [
                  { required: true, message: `请选择代金劵有效期` },
                  { validator: compareToEndTime }
                ]
              }]"
              @blur="handleConfirmBlur"
          />
        </div>

      </a-form-item>

      <a-form-item label="使用规则">
        <a-textarea
            v-decorator="['rule_content', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入使用规则' },
                { max: 1000, message: '最多1000个字符' },
              ]
            }]"
        />
      </a-form-item>

      <a-form-item v-if="activityType === 'discount_benefits'" label="发放对象">
        活动截止或失效之前，小程序所有的用户均可享有此福利
      </a-form-item>
      <a-form-item v-if="activityType === 'discount_benefits'" label="适用产品">
        <product ref="products" @completed="addProvideProducts"></product>
      </a-form-item>
    </a-form>
    <a-col :offset="3">
      <a-button type="primary" @click="handleSubmit">提交</a-button>
    </a-col>
  </div>

</template>

<script>
import {findVoucherUseTypeOption, newVoucher} from "@/api/voucher";
import {formatDateTime} from "@/utils/time";
import Provide from "@/views/voucher/Provide.vue";
import Product from "@/views/voucher/Product.vue";

export default {
  name: "Add",
  components: {Product, Provide},
  data() {
    return {
      submitting: false,
      effectiveType: 'infinite',
      form: this.$form.createForm(this, { name: 'voucher' }),
      voucherUseTypeOption: [],
      provideProducts:[],
      activityType: '',
      limit: '',
    }
  },

  created() {
    this.fetchVoucherUseTypeOption()
  },
  methods: {
    addProvideProducts(products,limit) {
      this.provideProducts = products
      this.limit = limit
    },
    changeActivityType(val){
      this.activityType = val
    },
    fetchVoucherUseTypeOption() {
      findVoucherUseTypeOption().then(res => {
        if (res.code === 0) {
          this.voucherUseTypeOption = res.data.result
        }
      })
    },

    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    handEffectiveType() {
      this.$nextTick(() => {
        this.effectiveType = this.form.getFieldValue('period_type')
      })
    },

    handleSubmit(e) {
      console.log(this.provideProducts)
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.activityType === 'discount_benefits') {
        if (this.$refs.products.limit === 'limit') {
          if (this.provideProducts.length === 0){
            this.$warning({
              title: '请设置适用产品',
              content: ''
            })
            return
          }
        }
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.end_time = formatDateTime(values.end_time)
          values.period_end_time = formatDateTime(values.period_end_time)
          if (this.activityType === 'discount_benefits') {
            values.limit = this.$refs.products.limit
            let product_ids = []
            if (this.provideProducts && this.provideProducts.length > 0) {
              for (let i = 0; i < this.provideProducts.length; i++) {
                product_ids.push(this.provideProducts[i].id)
              }
            }
            values.product_ids = product_ids
          }
          newVoucher(values).then(res => {
            this.submitting = false
            if (res.code === 0) {
              this.$router.push({ name: 'voucher' })
            }
          })
        }
      })
    },

    compareToEndTime(rule, value, callback) {
      const form = this.form
      if (value === undefined) {
        // eslint-disable-next-line standard/no-callback-literal
        this.$message.warning('请选择代金劵有效期')
        return
      }
      if (value && value < form.getFieldValue('end_time')) {
        // eslint-disable-next-line standard/no-callback-literal
        this.$message.warning('请选择的时间小于活动截止日期，请重新选择')
        callback()
      } else {
        callback()
      }
    },

    checkEndTime(rule, value, callback) {
      if (value === undefined) {
        // eslint-disable-next-line standard/no-callback-literal
        this.$message.warning('请输入代金劵有效期')
        return
      }
      callback()
    },

    checkUnit(rule, value, callback) {
      if (value === undefined) {
        // eslint-disable-next-line standard/no-callback-literal
        this.$message.warning('请选择代金劵有效期单位')
        return
      }
      callback()
    }
  }
}
</script>

<style scoped>

</style>
