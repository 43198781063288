<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="产品名称">
            <a-input
                v-decorator="['name', {
              normalize: this.$lodash.trim,
            }]"
                autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="拼音码">
            <a-input
                v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
            }]"
                autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="类型">
            <a-select
                v-decorator="['product_type']"
                :loading="typeOptions.length === 0"
                allow-clear
            >
              <a-select-option
                  v-for="type in typeOptions"
                  :key="type.slug"
                  :value="type.slug"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="products"
        :loading="loading"
        :pagination="false"
        row-key="id"
    >
    </a-table>

    <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
    />
  </div>
</template>

<script>

import {getProductType} from "@/api/product_type";
import {findVoucherProducts} from "@/api/voucher";
import Pagination from '@/components/Pagination'

export default {
  name: "DetailProduct",
  props:{
    voucherId: {
      type: Number,
      required: true
    }
  },
  components: {Pagination},
  data(){
    return {
      form: this.$form.createForm(this, {name: 'product_search'}),
      query: {},
      pagination:{
        total_count: 0
      },
      loading: false,
      typeOptions: [],
      products:[],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 200,
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 100
        },
        {
          title: '产品类型',
          dataIndex: 'product_type',
          width: 100
        },
      ]
    }
  },
  created() {
    this.getTypeOptions()
    this.fetchVoucherProduct()
  },
  methods: {
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },
    fetchData(){
      this.fetchVoucherProduct()
    },
    handleSearch(e){
      console.log(e)
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
      }
      this.query = values
      this.fetchVoucherProduct()
    },
    handleReset(){
      this.form.resetFields()
      this.query = {}
      this.fetchVoucherProduct()
    },
    fetchVoucherProduct(){
      // 查询优惠券产品信息
      findVoucherProducts(this.voucherId,Object.assign({}, this.query)).then(res => {
        if (res.code === 0) {
          this.products = res.data
          this.pagination = res.pagination
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
