<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="订单号">
            <span style="word-break: break-all">{{ order.order_no }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="订单类型">
            <span style="word-break: break-all">{{
              order.record_type_display
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="状态">
            {{ order.order_status }}
          </a-descriptions-item>
          <a-descriptions-item label="订单来源">
            {{ order.source }}
          </a-descriptions-item>
          <a-descriptions-item label="负责人">
            {{ order.head_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ order.created_at }}
          </a-descriptions-item>
          <a-descriptions-item label="所属租户">
            {{ order.tenant_name }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="申请人">
            {{ order.apply_name }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="丧属">
            {{ order.masterName }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.masterPhone }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="接运地点">
            {{ order.origination }}
          </a-descriptions-item>
          <a-descriptions-item label="目的地">
            {{ order.destination }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            {{ order.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="厅房">
            <span>{{ order.hall_name }}</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-descriptions
        :column="1"
        class="custom-descriptions custom-descriptions-max-7"
      >
        <a-descriptions-item label="套餐金额">
          <span style="word-break: break-all">{{ order.package_fee }}元</span>
        </a-descriptions-item>
<!--        <a-descriptions-item label="套餐实收金额">-->
<!--          <span style="word-break: break-all">{{ parseFloat(order.package_fee - order.voucher_fee).toFixed(2) }}元</span>-->
<!--        </a-descriptions-item>-->
        <a-descriptions-item label="其他项目金额">
          <span
            style="word-break: break-all"
          >{{ order.other_item_fee }}元</span>
        </a-descriptions-item>
        <a-descriptions-item label="优惠金额">
          <span style="word-break: break-all">{{ order.voucher_fee }}元</span>
        </a-descriptions-item>
        <a-descriptions-item label="厅房金额">
          <a-space>
            <span style="word-break: break-all">{{ order.hall_fee }}元</span>
          </a-space>
        </a-descriptions-item>

        <a-descriptions-item label="结算金额">
          <span style="word-break: break-all">{{ order.settle_fee }}元</span>
        </a-descriptions-item>
        <a-descriptions-item label="已实际支付金额">
          <span style="word-break: break-all">{{ order.paid_fee }}元</span>
        </a-descriptions-item>

        <a-descriptions-item label="剩余应付金额">
          <span style="word-break: break-all">{{ order.unpaid_fee }}元</span>
        </a-descriptions-item>
        <a-descriptions-item label="应退金额">
          <span style="word-break: break-all">{{ order.withdrawn_fee }}元</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-row>
  </div>
</template>

<script>
import { findOrderInfo } from '@/api/order'

export default {
  name: 'BasicInformation',
  components: {
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: {},
      isShowVoucher: false,
      isShowPaid: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    orderId() {
      return parseInt(this.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findOrderInfo(this.id).then((res) => {
        this.order = res.data
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}

/deep/
.ant-descriptions-item {
  display: flex;

  .ant-descriptions-item-content {
    display: flex;
  }

  .ant-descriptions-item span {
    display: table-cell;
  }
}

</style>
