import request from '@/utils/request'

// 租户小程序用户列表(租户)
export function findTenantMiniProgramUsers(params) {
  return request({
    url: `/tenant/mini_program_users`,
    method: 'get',
    params: params
  })
}

// 租户小程序用户列表(系统)
export function findSystemMiniProgramUsers(params) {
  return request({
    url: `/system/mini_program_users`,
    method: 'get',
    params: params
  })
}

// 租户小程序用户下拉框(租户)
export function findTenantMiniProgramUsersOption(params) {
  return request({
    url: `/tenant/mini_program_users_options`,
    method: 'get',
    params: params
  })
}

// 租户小程序用户列表(租户)
export function findTenantMiniProgramUserList(params) {
  return request({
    url: `/tenant/mini_program_user_list`,
    method: 'get',
    params: params
  })
}
