<template>
  <div>
    <a-radio-group
        v-model="limit"
        @change="changeLimit"
    >
      <a-radio value="limit">限定产品</a-radio>
      <a-radio value="no_limit">不限定产品</a-radio>
    </a-radio-group>
    <div v-if="limit==='limit'">
      <div style="display: flex;justify-content: space-between">
        <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
          <a-row :gutter="24">
            <a-col :span="8" :xxl="6">
              <a-form-item label="产品名称">
                <a-input
                    v-decorator="['product_name', {
              normalize: this.$lodash.trim,
            }]"
                    autocomplete="off"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8" :xxl="6">
              <a-form-item label="拼音码">
                <a-input
                    v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
            }]"
                    autocomplete="off"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8" :xxl="6">
              <a-form-item label="类型">
                <a-select
                    v-decorator="['product_type']"
                    :loading="typeOptions.length === 0"
                    allow-clear
                >
                  <a-select-option
                      v-for="type in typeOptions"
                      :key="type.slug"
                      :value="type.slug"
                  >
                    {{ type.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" :xxl="6">
              <a-form-item>
                <a-space>
                  <a-button type="primary" html-type="submit">
                    搜索
                  </a-button>
                  <a-button @click="handleReset">
                    刷新
                  </a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div>
          <a-space>
            <a-button type="danger" @click="allRemove">全部删除</a-button>
            <a-button type="primary" @click="addProduct">新增产品</a-button>
          </a-space>
        </div>
      </div>

      <a-table
          :scroll="{ x: 'max-content' }"
          :columns="columns"
          :data-source="products"
          :pagination="false"
          row-key="id"
      >

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="removeProduct(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
      </a-table>

      <add-provide-product v-if="isAddProduct" :visible.sync="isAddProduct" :ids="productIds" @completed="completeAddProducts"></add-provide-product>
    </div>
  </div>
</template>

<script>
import {getProductType} from "@/api/product_type";
import AddProvideProduct from "@/views/voucher/AddProvideProduct.vue";

export default {
  name: "Product",
  components: {
    AddProvideProduct,
  },
  data() {
    return {
      query: {},
      data: [],
      form: this.$form.createForm(this, { name: 'product_search' }),
      limit: 'limit',
      typeOptions: [],
      isAddProduct: false,
      productIds: [],
      provideProducts: [],
      products: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 100
        },
        {
          title: '产品类型',
          dataIndex: 'product_type',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'actions',
          width: 120,
          scopedSlots: {customRender: 'actions'}
        },
      ]
    }
  },
  created() {
    this.getTypeOptions()
  },
  methods: {
    allRemove() {
      this.provideProducts = []
      this.generateProducts()
      this.$emit('completed', this.provideProducts,this.limit)
    },
    completeAddProducts(products) {
      this.provideProducts.push(...products)
      this.generateProducts()
      this.$emit('completed', this.provideProducts,this.limit)
      console.log(products)
    },
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },
    changeLimit(val) {
      console.log(val)
      this.limit = val.target.value
      console.log(this.limit)
    },

    handleSearch(e) {
      e.preventDefault()
      this.query = this.form.getFieldsValue()
      this.generateProducts()
    },

    handleReset() {
      this.form.resetFields()
      this.query = this.form.getFieldsValue()
      this.generateProducts()
    },
    generateProducts() {
      this.products = []
      for (let i = 0; i < this.provideProducts.length; i++) {
        if (this.query.product_name) {
          if (this.provideProducts[i].name.indexOf(this.query.product_name) === -1) {
            continue
          }
        }
        if (this.query.pinyin) {
          if (this.provideProducts[i].pinyin.indexOf(this.query.pinyin) === -1) {
            continue
          }
        }
        if (this.query.product_type) {
          if (this.provideProducts[i].product_type_slug !== this.query.product_type) {
            continue
          }
        }
        this.products.push(this.provideProducts[i])
      }
    },
    addProduct() {
      this.productIds = []
      if (this.provideProducts && this.provideProducts.length > 0) {
        for (let i = 0; i < this.provideProducts.length; i++) {
          this.productIds.push(this.provideProducts[i].id)
        }
      }
      this.isAddProduct = true
    },
    removeProduct(productId){
      let tmpProducts = []
      for (let i = 0; i < this.provideProducts.length; i++) {
        if (this.provideProducts[i].id !== productId) {
          tmpProducts.push(this.provideProducts[i])
        }
      }
      this.provideProducts = tmpProducts
      this.generateProducts()
      this.$emit('completed', this.provideProducts,this.limit)
      console.log('1111')
    }
  }
}
</script>

<style scoped>

</style>
