<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="订单号">
            {{ order.order_no }}
          </a-descriptions-item>
          <a-descriptions-item label="订单类型">
            {{ order.record_type_display }}
          </a-descriptions-item>
          <a-descriptions-item label="状态">
            {{ order.order_status }}
          </a-descriptions-item>
          <a-descriptions-item label="订单来源">
            {{ order.source }}
          </a-descriptions-item>
          <a-descriptions-item label="负责人">
            {{ order.head_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ order.created_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="申请人">
            {{ order.apply_name }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="丧属">
            {{ order.masterName }}
          </a-descriptions-item>
          <a-descriptions-item label="联系电话">
            {{ order.masterPhone }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="接运地点">
            {{ order.origination }}
          </a-descriptions-item>
          <a-descriptions-item label="目的地">
            {{ order.destination }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            {{ order.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="厅房">
            <span>{{ order.hall_name }}</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="厅房价格">
            {{ order.hall_price }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="停棺时长">
            {{ order.package_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-divider />
    <a-row>
      <a-col span="12">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-7"
        >
          <a-descriptions-item label="套餐金额">
            <span style="word-break: break-all">{{ order.package_fee }}元</span>
          </a-descriptions-item>
<!--          <a-descriptions-item label="套餐实收金额">-->
<!--            <a-space>-->
<!--              <span style="word-break: break-all">{{ parseFloat(order.package_fee - order.voucher_fee).toFixed(2) }}元</span>-->
<!--            </a-space>-->
<!--          </a-descriptions-item>-->
          <a-descriptions-item label="其他项目金额">
            <span
              style="word-break: break-all"
            >{{ order.other_item_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="优惠金额">
            <a-space>
              <span style="word-break: break-all">{{ order.voucher_fee }}元</span>
              <span @click="voucherShow()" class="custom-blue">详情</span>
            </a-space>
          </a-descriptions-item>
          <a-descriptions-item label="厅房金额">
            <a-space>
              <span style="word-break: break-all">{{ order.hall_fee }}元</span>
              <span @click="hallFeeShow()" class="custom-blue">详情</span>
            </a-space>
          </a-descriptions-item>

          <a-descriptions-item v-if="order.is_disinfect === true && order.package_included_disinfection === true" label="消毒金额">
            <span style="word-break: break-all">{{ order.disinfection_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item v-if="order.is_disinfect === true && order.package_included_buddhist_venue === true" label="佛事场地费">
            <span style="word-break: break-all">{{ order.buddhist_venue_fee }}元</span>
          </a-descriptions-item>

          <a-descriptions-item label="结算金额">
            <span style="word-break: break-all">{{ order.settle_fee }}元</span>
            <span v-if="order.adjust" style="margin-left: 10px;color: red;">(调账)</span>
          </a-descriptions-item>
          <a-descriptions-item label="已实际支付金额">
            <a-space>
              <span style="word-break: break-all">{{ order.paid_fee }}元</span>
              <span @click="paidShow()" class="custom-blue">详情</span>
            </a-space>
          </a-descriptions-item>

          <a-descriptions-item label="剩余应付金额">
            <span style="word-break: break-all">{{ order.unpaid_fee }}元</span>
          </a-descriptions-item>
          <a-descriptions-item label="应退金额">
            <span style="word-break: break-all">{{ order.withdrawn_fee }}元</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col span="12">
        <qrcode-vue
          v-if="order.qr_code_url"
          :value="order.qr_code_url"
          :size="200"
          level="H"
        />
      </a-col>
    </a-row>
    <!-- 已实际支付金额详情模态框 -->
    <show-voucher
      v-if="isShowVoucher"
      :visible.sync="isShowVoucher"
      :order-id="orderId"
    />
    <!-- 优惠金额详情模态框 -->
    <show-paid
      v-if="isShowPaid"
      :visible.sync="isShowPaid"
      :order-id="orderId"
    />
    <show-hall-fee
      v-if="isShowHallFee"
      :visible.sync="isShowHallFee"
      :order="order"
    />
  </div>
</template>

<script>
import { findOrderInfo } from '@/api/order'

export default {
  name: 'BasicInformation',
  components: {
    ShowPaid: () => import('@/views/orders/tenant/ShowPaid'),
    ShowVoucher: () => import('@/views/orders/tenant/ShowVoucher'),
    QrcodeVue: () => import('qrcode.vue'),
    ShowHallFee: () => import('@/views/orders/tenant/ShowHallFee.vue')
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: {},
      isShowVoucher: false,
      isShowPaid: false,
      isShowHallFee: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    orderId() {
      return parseInt(this.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    paidShow() {
      this.isShowPaid = true
    },
    voucherShow() {
      this.isShowVoucher = true
    },
    hallFeeShow() {
      this.isShowHallFee = true
    },
    fetchData() {
      findOrderInfo(this.id).then((res) => {
        this.order = res.data
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

