import request from '@/utils/request'

// 查询订单列表（租户）
export function findTenantOrdersList(params) {
  return request({
    url: `/tenant/orders`,
    method: 'get',
    params: params
  })
}
// 刷新详情页数据
export function findTenantOrder(id) {
  return request({
    url: `/tenant/orders/${id}/refresh`,
    method: 'get'
  })
}

// 查询订单列表（集团）
export function findSystemOrdersList(params) {
  return request({
    url: `/system/orders`,
    method: 'get',
    params: params
  })
}

// 查询订单
export function findOrderInfo(id) {
  return request({
    url: `/orders/${id}/service_order`,
    method: 'get'
  })
}

// 殡仪服务订单二维码
export function findServiceOrderQrCodeUrl(id) {
  return request({
    url: `/orders/${id}/service_order/qr_code_url`,
    method: 'get'
  })
}

// 查询订单
export function findSubscribeOrderInfo(id) {
  return request({
    url: `/orders/${id}/subscribe_order`,
    method: 'get'
  })
}

// 订单类型
export function findOrderTypeOptions() {
  return request({
    url: `/orders/type_options`,
    method: 'get'
  })
}

// 订单状态
export function findOrderStatusOptions(params) {
  return request({
    url: `/orders/status_options`,
    method: 'get',
    params: params
  })
}
// 订单来源
export function findOrderSourceOptions(params) {
  return request({
    url: `/orders/source_options`,
    method: 'get',
    params: params
  })
}

// 逝者基本信息
export function findServiceOrderDeathBasicInfo(id) {
  return request({
    url: `/orders/${id}/basic_info`,
    method: 'get'
  })
}

// 逝者家属信息
export function findServiceOrderDeathFamilyInfo(id) {
  return request({
    url: `/orders/${id}/family_info`,
    method: 'get'
  })
}

// 逝者家族谱信息
export function findServiceOrderDeathGenealogy(id) {
  return request({
    url: `/orders/${id}/genealogy`,
    method: 'get'
  })
}

// 套餐信息
export function findOrderPackages(id) {
  return request({
    url: `/orders/${id}/package_info`,
    method: 'get'
  })
}

// 查询套餐以外收费项目和佛事注意事项
export function findExtraContentInfoAndBuddhistNotesInfo(id) {
  return request({
    url: `/orders/${id}/service_order_package`,
    method: 'get'
  })
}

// 套餐信息
export function findProjectSummary(id, params) {
  return request({
    url: `/orders/${id}/project_summary`,
    method: 'get',
    params: params
  })
}

export function findItemAccountingSummary(id, params) {
  return request({
    url: `/orders/${id}/item_accounting_summary`,
    method: 'get',
    params: params
  })
}
export function findConfirmSummary(id, params) {
  return request({
    url: `/orders/${id}/find_confirm_summary`,
    method: 'get',
    params: params
  })
}

// 订购记录
export function findServiceOrderOtherItems(id) {
  return request({
    url: `/orders/${id}/service_order_other_items`,
    method: 'get'
  })
}

// 任务列表
export function findOrderTasks(id) {
  return request({
    url: `/orders/${id}/tasks`,
    method: 'get'
  })
}

// 已支付列表
export function findServiceOrderPaidList(id) {
  return request({
    url: `/tenant/orders/${id}/service_order_paid_list`,
    method: 'get'
  })
}

// 优惠详情
export function findServiceOrderVouchers(id) {
  return request({
    url: `/tenant/orders/${id}/service_order_vouchers`,
    method: 'get'
  })
}

export function findOrderVouchers(id) {
  return request({
    url: `/tenant/orders/${id}/vouchers`,
    method: 'get'
  })
}

// 高拍仪上传
export function uploadGaoPaiYi(id, data) {
  return request({
    url: `/tenant/orders/${id}/upload_gpy`,
    method: 'post',
    data
  })
}

// 上传本地文件
export function uploadLocal(id, data) {
  return request({
    url: `/tenant/orders/${id}/upload_local`,
    method: 'post',
    data
  })
}

// 查询订单资料
export function findOrderFiles(id) {
  return request({
    url: `/tenant/orders/${id}/files`,
    method: 'get'
  })
}

// 删除订单资料
export function deleteOrderFile(id, data) {
  return request({
    url: `/tenant/orders/${id}/file`,
    method: 'delete',
    data
  })
}

// 挽联信息
export function findElegiacInformation(params) {
  return request({
    url: `/orders/elegiac_information`,
    method: 'get',
    params: params
  })
}

// 选择套餐
export function findSelectOrderPackages(id, params) {
  return request({
    url: `/packages/${id}/select`,
    method: 'get',
    params: params
  })
}

// 创建结算清单pdf
export function createSettlementPDF(id) {
  return request({
    url: `/tenant/orders/${id}/settlement_pdf`,
    method: 'post'
  })
}
// 创建待结算项目结算清单pdf
export function createUnpaidSettlementPDF(id) {
  return request({
    url: `/tenant/orders/${id}/unpaid_settlement_pdf`,
    method: 'post'
  })
}
// 查询产品
export function findSubscribeOrderProducts(id) {
  return request({
    url: `/orders/${id}/subscribe_order_products`,
    method: 'get'
  })
}

// 确认接单
export function confirmOrder(data) {
  return request({
    url: `/tenant/orders/confirm_order`,
    method: 'post',
    data
  })
}

// 修改任务负责人
export function updateTaskHeadUser(data) {
  return request({
    url: `/tenant/orders/update_task_head_user`,
    method: 'post',
    data
  })
}

// 修改丧属
export function modifyMaster(data) {
  return request({
    url: `/tenant/orders/modify_master`,
    method: 'post',
    data
  })
}

// 修改丧属
export function validUpdateOrderMaster(data) {
  return request({
    url: `/tenant/orders/valid_update_order_master`,
    method: 'post',
    data
  })
}

// 修改负责人
export function modifyHeadUser(data) {
  return request({
    url: `/tenant/orders/modify_head_user`,
    method: 'post',
    data
  })
}

// 修改负责人申请
export function validUpdateOrderHeadUser(data) {
  return request({
    url: `/tenant/orders/valid_update_order_head_user`,
    method: 'post',
    data
  })
}

// 取消订单
export function cancelOrder(data) {
  return request({
    url: `/tenant/orders/cancel_order`,
    method: 'post',
    data
  })
}

// 送达穿衣间
export function toDressRoom(data) {
  return request({
    url: `/tenant/orders/to_dress_room`,
    method: 'post',
    data
  })
}

// 查询殡仪交接确认信息
export function findConfirmFuneralHandover(id) {
  return request({
    url: `/tenant/orders/${id}/confirm_funeral_handover`,
    method: 'get'
  })
}

// 殡仪交接确认
export function confirmFuneralHandover(id, data) {
  return request({
    url: `/tenant/orders/${id}/confirm_funeral_handover`,
    method: 'post',
    data
  })
}

// 发送办理相关手续告知书
export function sendProcessNotificationNotice(data) {
  return request({
    url: `/tenant/orders/send_process_notification`,
    method: 'post',
    data
  })
}

// 调账
export function reconciliationOrder(data) {
  return request({
    url: `/tenant/orders/reconciliation_order`,
    method: 'post',
    data
  })
}

// 发送结算提醒
export function sendPaymentReminder(data) {
  return request({
    url: `/tenant/orders/send_payment_reminder`,
    method: 'post',
    data
  })
}

// 终止订单
export function terminationOrder(data) {
  return request({
    url: `/tenant/orders/termination_order`,
    method: 'post',
    data
  })
}

// 结算订单
export function billingOrder(data) {
  return request({
    url: `/tenant/orders/billing_order`,
    method: 'post',
    data
  })
}

// 选择厅房
export function chooseHall(data) {
  return request({
    url: `/tenant/orders/choose_hall`,
    method: 'post',
    data
  })
}

// 修改厅房
export function changeHall(data) {
  return request({
    url: `/tenant/orders/change_hall`,
    method: 'post',
    data
  })
}

// 选择套餐
export function choosePackage(id, data) {
  return request({
    url: `/service_orders/${id}/choose_package`,
    method: 'post',
    data
  })
}

// 发送戴号提醒
export function sendWearArmbandNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_wear_armband`,
    method: 'post',
    data
  })
}

// 获取戴号仪式信息
export function findObituaryInfo(id, params) {
  return request({
    url: `/service_orders/${id}/obituary_info`,
    method: 'get',
    params
  })
}

// 制作讣告
export function makeObituary(id, data) {
  return request({
    url: `/service_orders/${id}/make_obituary`,
    method: 'post',
    data
  })
}

// 进行戴号仪式
export function wearArmband(id, data) {
  return request({
    url: `/service_orders/${id}/wear_armband`,
    method: 'post',
    data
  })
}

// 准备供菜供饭任务
export function prepareProvideFoodCeremony(id, data) {
  return request({
    url: `/service_orders/${id}/prepare_tribute`,
    method: 'post',
    data
  })
}

// 发送第一夜守灵提醒
export function sendFirstNightWatchingNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_first_watch`,
    method: 'post',
    data
  })
}

// 发送第一夜守灵提醒
export function sendSecondNightWatchingNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_second_watch`,
    method: 'post',
    data
  })
}

// 通知负责人
export function sendUserNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_user_notice`,
    method: 'post',
    data
  })
}

// 确定其他项目
export function confirmOtherItem(id, data) {
  return request({
    url: `/service_orders/${id}/confirm_other`,
    method: 'post',
    data
  })
}

// 修改其他项目
export function updateOtherItem(id, data) {
  return request({
    url: `/service_orders/${id}/update_other`,
    method: 'post',
    data
  })
}

// 确认逝者生平事迹
export function confirmedLifeInfo(id, data) {
  return request({
    url: `/service_orders/${id}/confirm_life`,
    method: 'post',
    data
  })
}

// 上传司仪稿
export function uploadEmceeDraft(id, data) {
  return request({
    url: `/service_orders/${id}/upload_emcee_draft`,
    method: 'post',
    data
  })
}

// 发送奠盒仪式提醒
export function sendLibationBoxCeremonyNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_libation_box`,
    method: 'post',
    data
  })
}

// 发送感恩仪式式提醒
export function sendThankfulCeremonyNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_thankful_ceremony`,
    method: 'post',
    data
  })
}

// 发送就餐提醒
export function sendMealNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_meal_notice`,
    method: 'post',
    data
  })
}

// 发送出殡提醒
export function sendFuneralCeremonyNotice(id, data) {
  return request({
    url: `/service_orders/${id}/send_funeral_ceremony`,
    method: 'post',
    data
  })
}

// 完成出殡
export function finishHoldFuneralCeremony(id, data) {
  return request({
    url: `/service_orders/${id}/finish_funeral_ceremony`,
    method: 'post',
    data
  })
}

// 确认理事工作
export function confirmDirector(id, data) {
  return request({
    url: `/service_orders/${id}/confirm_director`,
    method: 'post',
    data
  })
}

export function productOptions(id) {
  return request({
    url: `/service_orders/${id}/product_options`,
    method: 'get'
  })
}

// 新增项目
export function addAdditionalProduct(id, data) {
  return request({
    url: `/service_orders/${id}/add_additional_product`,
    method: 'post',
    data
  })
}

// 上传遗像视频任务视频或遗像
export function uploadDeathPhotoOrVideoTask(id, data) {
  return request({
    url: `/service_orders/${id}/upload_death_photo_or_video_task`,
    method: 'post',
    data
  })
}

export function appointment(data) {
  return request({
    url: `/service_orders/appointment`,
    method: 'post',
    data
  })
}

// 打印家属告知书
export function printFamilyNoticePdf(id) {
  return request({
    url: `tenant/orders/${id}/family_notice_pdf`,
    method: 'post'
  })
}

// 打印穿衣交接单
export function printHandoverPdf(id) {
  return request({
    url: `tenant/orders/${id}/handover_pdf`,
    method: 'post'
  })
}

// 打印相关手续告知书
export function printRelevantProceduresPDF(id) {
  return request({
    url: `tenant/orders/${id}/relevant_procedures_pdf`,
    method: 'post'
  })
}

// 标记线下支付项目
export function settlementOtherItems(id, data) {
  return request({
    url: `service_orders/${id}/settlement_other_items`,
    method: 'put',
    data
  })
}

// 打印勾选项目结算pdf
export function settlementOtherItemsPDF(id, params) {
  return request({
    url: `service_orders/${id}/settlement_other_items_pdf`,
    method: 'get',
    params: params
  })
}

// 更新接运地址
export function updateTransportation(id, data) {
  return request({
    url: `service_orders/${id}/update_transportation`,
    method: 'put',
    data
  })
}

// 创建或者更新礼厅服务
export function createOrUpdateAuditoriumService(id, data) {
  return request({
    url: `/tenant/orders/${id}/auditorium_service`,
    method: 'post',
    data
  })
}

// 获取礼厅服务表单
export function findAuditoriumServiceForm(id) {
  return request({
    url: `/tenant/orders/${id}/auditorium_service`,
    method: 'get'
  })
}

// 礼厅服务pdf
export function createAuditoriumServicePdf(id) {
  return request({
    url: `/tenant/orders/${id}/auditorium_service_pdf`,
    method: 'post'
  })
}

export function updateHallTime(id,data){
  return request({
    url: `/service_orders/${id}/update_hall_time`,
    method: 'put',
    data
  })
}

export function updateThankfulTime(id,data){
  return request({
    url: `/service_orders/${id}/update_thankful_time`,
    method: 'put',
    data
  })
}

export function updateFuneralTime(id,data){
  return request({
    url: `/service_orders/${id}/update_funeral_time`,
    method: 'put',
    data
  })
}

export function findFuneralTimeForm(id){
  return request({
    url: `/service_orders/${id}/find_funeral_time_form`,
    method: 'get',
  })
}
export function findHallTimeForm(id){
  return request({
    url: `/service_orders/${id}/find_hall_time_form`,
    method: 'get',
  })
}
export function findThankfulTimeForm(id){
  return request({
    url: `/service_orders/${id}/find_thankful_time_form`,
    method: 'get',
  })
}
