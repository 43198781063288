<template>
  <div>
    <a-form
        class="custom-compact-form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
    >
      <a-form-item label="活动名称">
        {{ voucher.name }}
      </a-form-item>

      <a-form-item label="活动类型">
        {{ voucher.activity_type }}
      </a-form-item>

      <a-form-item label="活动截止日期">
        {{ voucher.end_time }}
      </a-form-item>

      <a-form-item label="代金券金额(元)">
        {{ voucher.amount }}
      </a-form-item>

      <a-form-item label="使用条件">
        {{ voucher.use_type }}
      </a-form-item>

      <!--        代金劵有效期-->
      <a-form-item v-if="voucher.activity_type_slug !== 'discount_benefits'" label="代金劵有效期">
        {{ voucher.period_type }}
      </a-form-item>

      <a-form-item label="使用规则">
        {{ voucher.rule_content }}
      </a-form-item>
      <a-form-item v-if="voucher.activity_type_slug === 'discount_benefits'" label="发放对象">
        活动截止或失效之前，小程序所有的用户均可享有此福利
      </a-form-item>
      <a-form-item v-if="voucher.activity_type_slug === 'discount_benefits'" label="适用产品">
        {{ voucher.limit ? '限定产品' : '不限定产品'}}
        <detail-product v-if="voucher.limit " :voucher-id="voucherId"></detail-product>
      </a-form-item>
    </a-form>
  </div>

</template>

<script>
import Provide from "@/views/voucher/Provide.vue";
import Product from "@/views/voucher/Product.vue";
import {getProductType} from "@/api/product_type";
import {findVoucher} from "@/api/voucher";
import pagination from "ant-design-vue/lib/pagination";
import DetailProduct from "@/views/voucher/DetailProduct.vue";

export default {
  name: "Detail",
  components: {DetailProduct, Product, Provide,pagination},
  data() {
    return {
      voucher: {},
    }
  },

  created() {
    this.fetchVoucherInfo()
  },
  computed: {
    voucherId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 100
        },
        {
          title: '产品类型',
          dataIndex: 'product_type',
          width: 100
        },
      ]
    }
  },
  methods: {
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },

    fetchVoucherInfo() {
      //查询优惠券信息
      findVoucher(this.voucherId).then(res=>{
        if (res.code === 0) {
          this.voucher = res.data
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
