<template>
  <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增产品"
      @ok="handleSubmit"
      :width="1000"
  >
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="产品名称">
            <a-input
                v-decorator="['name', {
              normalize: this.$lodash.trim,
            }]"
                autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="拼音码">
            <a-input
                v-decorator="['code', {
              normalize: this.$lodash.trim,
            }]"
                autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="产品类型">
            <a-select
                v-decorator="['product_type']"
                :loading="typeOptions.length === 0"
                allow-clear
            >
              <a-select-option
                  v-for="type in typeOptions"
                  :key="type.slug"
                  :value="type.slug"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        row-key="id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
    </a-table>
  </a-modal>
</template>

<script>
import {getProductType} from "@/api/product_type";
import {findTenantProductList} from "@/api/product";

export default {
  name: "AddProvideProduct",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    ids: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      query: {},
      data: [],
      form: this.$form.createForm(this, { name: 'user_search' }),
      selectedRowKeys: [],
      submitting: false,
      typeOptions:[]
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 150
        },
        {
          title: '产品类型',
          width: 100,
          dataIndex: 'product_type',
        },
      ]
    }
  },
  created() {
    this.getTypeOptions()
    this.fetchProducts()
  },
  methods: {
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    fetchProducts() {
      this.data = []
      findTenantProductList(Object.assign({}, this.query)).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              if (!this.ids.includes(res.data[i].id)) {
                this.data.push(res.data[i])
              }
            }
          }
        }
      })
    },
    handleReset() {
      this.form.resetFields()
      this.query = {}
      this.fetchProducts()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      let products = []
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.selectedRowKeys.includes(this.data[i].id)) {
            products.push(this.data[i])
          }
        }
      }
      this.isShow = false
      this.$emit('completed',products)
    },
    handleSearch(e){
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
      }
      this.query = values
      this.fetchProducts()
    }
  }
}
</script>

<style scoped>

</style>
