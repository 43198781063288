<template>
  <a-modal
    width="900px"
    v-model="isShow"
    title="优惠详情"
    :mask-closable="false"
    :body-style="{ paddingLeft: 20 }"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loading"
    />
  </a-modal>
</template>

<script>

import {findOrderVouchers} from '@/api/order'

export default {
  name: 'ShowVoucher',
  props: {
    orderId: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      data: [],
      loading: true
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '代金券编号',
          dataIndex: 'voucher_number',
          fixed: 'left'
        },
        {
          title: '优惠类型',
          dataIndex: 'voucher_activity_type_text'
        },
        {
          title: '使用时间',
          dataIndex: 'use_at'
        },
        {
          title: '有效期限',
          dataIndex: 'validity_period'
        },
        {
          title: '相关支付单号',
          dataIndex: 'payment_order_number'
        },
        {
          title: '优惠金额(元)',
          dataIndex: 'voucher_amount'
        }
      ]
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },
    fetchData() {
      this.loading = true
      findOrderVouchers(this.orderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
